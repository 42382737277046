'use client';

import { SnackbarProvider } from 'notistack';
import { createPortal } from 'react-dom';

export const SnackbarProviderWrapper = () => {
  if (typeof window === 'undefined') return null;

  return createPortal(<SnackbarProvider />, document.body);
};
